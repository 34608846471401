import React, { useState, useEffect, useRef } from "react";
// import mapboxgl from "mapbox-gl";
import * as yup from "yup";
import { alphabet, alphaNumericPattern, email } from "../core/regex";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { postContactForm } from "../../redux/Actions";

import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "auto",
};

const center = {
  lat: 51.4225,
  lng: -0.2130979,
};

// mapboxgl.accessToken =
//   "pk.eyJ1IjoiaW5iYXJhc2FuciIsImEiOiJjbGtiMWExOHowYW5yM2NvMDFvNnY2dG8zIn0.g2q3UFtgxoP_3L9L3LplnA";
const contactUsFormSchema = yup.object({
  name: yup
    .string()
    .required("Name is required")
    .max(50, "Enter cannot exceed 50 character")
    .matches(alphabet, "Name should be only alphabet")
    .trim(),
  email: yup
    .string()
    .required("Email address is required")
    .matches(email, "Enter a valid email address")
    .max(96, "Enter cannot exceed 96 character")
    .trim(),
  message: yup
    .string()
    .required("Message is required")
    .matches(
      alphaNumericPattern,
      "Message cannot not contain any special character"
    )
    .max(250, "Message cannot exceed 250 character")
    .trim(),
});

function MapSection() {
  const {
    handleSubmit: contactFormSubmit,
    control: contactFormControl,
    reset: contactFormReset,
    formState: { errors: contactFormErrors },
  } = useForm({
    resolver: yupResolver(contactUsFormSchema),
    defaultValues: {
      email: "",
      message: "",
      name: "",
    },
  });

  const [useContactUs, setContactUs] = useState<any>("contactus-grid");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const mapContainer = useRef<any>(null);
  const map = useRef<any>(null);
  const [lng, setLng] = useState(-70.9);
  const [lat, setLat] = useState(42.35);
  const [zoom, setZoom] = useState(9);
  const [showDays, setShowDays] = useState<boolean>(false);

  const checkToday = (checkDay: string) => {
    let weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    let day = new Date().getDay();
    return checkDay == weekDays[day];
  };
  const getTodayTiming = () => {
    let weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    let day = new Date().getDay();
    let timing = weekDays[day] == "Sat" ? "08:00 - 18:00" : "07:00 - 19:00";
    return timing;
  };

  // useEffect(() => {
  //   if (map.current) return; // initialize map only once
  //   map.current = new mapboxgl.Map({
  //     container: mapContainer.current,
  //     style: "mapbox://styles/mapbox/streets-v12",
  //     center: [lng, lat],
  //     zoom: zoom,
  //   });
  // });

  const destination = { lat, lng };

  const handleOpenMaps = () => {
    const url = `https://www.google.com/maps/dir//41+Wimbledon+Hill+Rd,+London+SW19+7NA,+UK/@51.4225,-0.2130979,16z/data=!4m8!4m7!1m0!1m5!1m1!1s0x487608b7ae0c0107:0x72e86a214e2a25a5!2m2!1d-0.2087205!2d51.4225001?entry=ttu`;
    window.open(url, "_blank");
  };

  const submitContactForm = async (data: any) => {
    const response: any = await postContactForm(data);
    if (response.status) {
      setContactUs("contactus-grid");
      contactFormReset();
    } else {
      setErrorMessage(response.message);
    }
  };

  return (
    <>
      <section className="mapsection">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title text-center">
                <h2>Picking and Delivering Services Available!</h2>
                <p>
                  {" "}
                  Experience our hassle-free free pick-up and delivery services
                  for all your dry-cleaning, laundry,
                  <br /> and wash/dry needs, available daily!{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 px-0">
              <div className="contactuscontainer">
                <div className="hours-section-grid">
                  <div className="hours-section">
                    <h4>Wimbledon Dry Cleaners</h4>
                    <p>106 The broadway, SW19 1 RH</p>
                    <p>Landline: 0208 9463367 --- Mobile: 07522269722</p>
                  </div>
                  <div className="hours-section">
                    <h4>Wimbledon Dry Cleaners</h4>
                    <p>
                      106 The broadway, SW19 1 RH, United
                      Kingdom
                    </p>
                  </div>
                  <div className="hours-section">
                    <h4>Hours</h4>
                    <p>
                      Open today
                      <span className="ms-3">
                        {getTodayTiming()}
                        <i
                          onClick={() => {
                            setShowDays(!showDays);
                          }}
                          className="fas fa-caret-down ms-2"
                        ></i>
                      </span>
                    </p>
                    <div
                      className={`workinghours-list  ${
                        showDays ? "" : "d-none"
                      }`}
                    >
                      <ul>
                        <li className={`${checkToday("Mon") ? "active" : ""}`}>
                          <p>Mon 07:00 - 19:00</p>
                        </li>
                        <li className={`${checkToday("Tue") ? "active" : ""}`}>
                          <p>Tue 07:00 - 19:00</p>
                        </li>
                        <li className={`${checkToday("Wed") ? "active" : ""}`}>
                          <p>Wed 07:00 - 19:00</p>
                        </li>
                        <li className={`${checkToday("Thu") ? "active" : ""}`}>
                          <p>Thu 07:00 - 19:00</p>
                        </li>
                        <li className={`${checkToday("Fri") ? "active" : ""}`}>
                          <p>Fri 07:00 - 19:00</p>
                        </li>
                        <li className={`${checkToday("Sat") ? "active" : ""}`}>
                          <p>Sat 08:00 - 18:00</p>
                        </li>
                        <li className={`${checkToday("Sun") ? "active" : ""}`}>
                          <p>Sun Closed</p>
                        </li>
                      </ul>
                    </div>
                    <p>Closed on Bank Holidays.</p>
                    <p>
                      Please do call on <strong>07522269722</strong> should you
                      require further information.{" "}
                    </p>
                    <div className="hours-section-btn">
                      <button
                        className="btn contus-btn"
                        onClick={() => setContactUs("contactus-grid show")}
                      >
                        Drop us a line!
                      </button>
                    </div>
                  </div>
                </div>
                <div className={useContactUs}>
                  <div className="contactusform">
                    <form onSubmit={contactFormSubmit(submitContactForm)}>
                      <h4>Drop us a line!</h4>
                      <div className="mb-5 form-row">
                        <Controller
                          name="name"
                          control={contactFormControl}
                          render={({ field }) => (
                            <input
                              {...field}
                              className={`${
                                contactFormErrors?.name ? "invalid" : ""
                              }`}
                            />
                          )}
                        />
                        <label
                          className={`${
                            contactFormErrors?.name ? "text-danger" : ""
                          }`}
                        >
                          Name *
                        </label>
                        {contactFormErrors.name ? (
                          <small className="text-danger">
                            {contactFormErrors.name?.message}
                          </small>
                        ) : null}
                      </div>
                      <div className="mb-5 form-row">
                        <Controller
                          name="email"
                          control={contactFormControl}
                          render={({ field }) => (
                            <input
                              {...field}
                              className={`${
                                contactFormErrors?.email ? "invalid" : ""
                              }`}
                            />
                          )}
                        />
                        <label
                          className={`${
                            contactFormErrors?.name ? "text-danger" : ""
                          }`}
                        >
                          Email *
                        </label>
                        {contactFormErrors.email ? (
                          <small className="text-danger">
                            {contactFormErrors.email?.message}
                          </small>
                        ) : null}
                      </div>
                      <div className="mb-5 form-row">
                        <Controller
                          name="message"
                          control={contactFormControl}
                          render={({ field }) => (
                            <textarea
                              {...field}
                              className={`${
                                contactFormErrors?.message
                                  ? "invalid text-danger"
                                  : ""
                              }`}
                              placeholder="Message *"
                            ></textarea>
                          )}
                        />
                        {contactFormErrors.message ? (
                          <small className="text-danger">
                            {contactFormErrors.message?.message}
                          </small>
                        ) : null}
                      </div>
                      {errorMessage != "" ? (
                        <small className="text-danger">{errorMessage}</small>
                      ) : null}
                      <div className="contact-btn-group">
                        <button className="btn contus-btn">SEND</button>
                        <button
                          className="btn btncancel"
                          type="reset"
                          onClick={() => setContactUs("contactus-grid")}
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 px-0">
              <div className="map-grid">
                <div className="sidebarmap" onClick={() => handleOpenMaps()}>
                  <i className="fas fa-paper-plane me-2"></i> Get directions
                </div>
                {/* <div ref={mapContainer} className="map-container"></div> */}
                <LoadScript googleMapsApiKey="AIzaSyD16e2kojKKNSXi01K-7hx-f7eVtgGvgXQ">
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={10}
                  >
                    {/* Add markers or other map components here */}
                    <Marker position={center} />
                  </GoogleMap>
                </LoadScript>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default MapSection;
